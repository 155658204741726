<template>
    <div class="h-full my-auto">
        <v-container fluid class="h-full my-auto">
            <v-row class="h-full my-auto">
                <v-col cols="12" class="h-full my-auto">
                    <div
                        class="relative flex items-top justify-center h-full bg-gray-100 dark:bg-gray-900 sm:items-center py-4 sm:pt-0">
                        <div
                            class="min-w-screen overflow-x-hidden  my-auto  outline-none focus:outline-none  items-center flex justify-center">
                            <div class="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
                                <div class="">
                                    <div class="text-center p-5 flex-auto justify-center align-middle">
                                        <svg class="w-6 h-6 mx-auto" fill="none" stroke="currentColor"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122">
                                            </path>
                                        </svg>
                                        <h2 class="text-xl font-bold py-4 ">Update Your Bank Card</h2>
                                        <p class="text-sm text-gray-500 px-8">Please click the button below to initiate
                                            the process to update your linked bank.
                                            This app will be reading only your bank transactions and balance. This means
                                            it will have no access to initiating payments.
                                        </p>

                                        <v-row justify="center" class="p-5 flex flex-col">
                                            <v-col cols="3" class="mx-auto">
                                                <plaid-link clientName="pennytoast" env="development"
                                                    :publicKey="updateToken.token"
                                                    :product="['auth', 'transactions', 'balance']" :language="'en'"
                                                    :countryCodes="['GB']" :isWebView="true" :token=updateToken.token
                                                    webhook="https://api.pennytoast.com/api/webhooks"
                                                    :onLoad=onLoad :onEvent=onEvent :onExit=onExit :onSuccess=onSuccess
                                                    :loading="loading" @updateLoading="updateLoading"></plaid-link>
                                            </v-col>
                                        </v-row>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import PlaidLink from '../../components/Link/PlaidLink.vue'
import { mapGetters } from 'vuex'
// import axios from 'axios'

export default {
    name: 'updateItem',
    data() {
        return {
            publicToken: '',
            loading: false,
            error: null,
            metadata: null,
            eventName: null
        }
    },

    components: { PlaidLink },

    mounted() {
        if (this.updateToken.token === undefined) {
            this.$router.push('/cards')
        }
    },

    computed: {
        ...mapGetters({
            updateToken: 'getUpdateToken'
        }),
        userId() {
            return this.$session.get('user').id
        }
    },

    watch: {
        publicToken() {
            if (this.publicToken === null || this.publicToken === '') {
                this.$router.push('/cards')
            }
        }
    },

    methods: {
        async onSuccess(token) {
            // console.log('public token: ' + token);
            this.loading = false
            this.publicToken = token
            // let payload = {user_id: this.userId, item_id: this.updateToken.item}
            // show succes message and redirect user
            // send request /api/items/updated to trigger fetching data for new account
            // try {
            //     await axios.post(process.env.VUE_APP_API_URL + '/items/updated', payload, { headers: { "Content-Type": "application/json" } })
            //     this.publicToken = null
            // } catch (error) {
            //     console.log('Failed to send update item request');
            // }
        },
        onExit(error, metadata) {
            this.error = error
            this.metadata = metadata
            // console.log('Exited: error: ' + JSON.stringify(error) + ' metadata: ' + JSON.stringify(metadata));
            this.loading = false
        },
        onEvent(eventName, metadata) {
            this.eventName = eventName
            this.metadata = metadata
            // console.log('event: ' + eventName + ' metadata: ' + JSON.stringify(metadata));
        },
        onLoad() {
            // console.log('on load called');
        },
        updateLoading(value) {
            this.loading = value
        }
    }
}
</script>