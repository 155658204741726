<template>
<div class="plaid-link-wrapper">
    <slot name="button" v-bind:onClick="handleOnClick">
        <v-btn class="mx-auto mb-4" :loading="loading" block rounded dark color="red" @click="handleOnClick">Start</v-btn>
    </slot>
</div>
</template>

<script>
// import { Messages } from '@/utils/constants';
export default {
    name: "PlaidLink",
    props: {
        loading: Boolean,
        plaidUrl: {
        type: String,
        default: "https://cdn.plaid.com/link/v2/stable/link-initialize.js",
        },
        env: {
        type: String,
        default: "sandbox",
        },
        institution: String,
        selectAccount: Boolean,
        token: String,
        product: {
        type: [String, Array],
        default: function () {
            return ["auth"];
        },
        },
        receivedRedirectUri: {
        type: String,
        default: null,
        },
        language: String,
        countryCodes: Array,
        isWebView: Boolean,
        clientName: String,
        publicKey: String,
        webhook: String,
        onLoad: Function,
        onSuccess: Function,
        onExit: Function,
        onEvent: Function,
    },
    data() {
        return {
            error: null,
        }
    },
    created() {
        this.loadScript(this.plaidUrl)
        .then(this.onScriptLoaded)
        .catch(this.onScriptError);
    },
    beforeDestroy() {
        if (window.linkHandler) {
        window.linkHandler.exit();
        }
    },
    methods: {
        updateLoading(loading) {
            this.$emit('updateLoading', loading)
        },
        onScriptError(error) {
            this.error = error;
            // console.log(error);
            console.error("There was an issue loading the link-initialize.js script");
            // this.$store.dispatch('showSnackBar', {status: true, message: error.response.data.message, type: Messages.ERROR})

        },
        onScriptLoaded() {
            let props = {
                clientName: this.clientName,
                env: this.env,
                key: this.publicKey,
                onExit: this.onExit,
                onEvent: this.onEvent,
                onSuccess: this.onSuccess,
                product: this.product,
                selectAccount: this.selectAccount,
                token: this.token,
                webhook: this.webhook,
            };

            if (this.receivedRedirectUri) {
                props.receivedRedirectUri = this.receivedRedirectUri;
            }

            window.linkHandler = window.Plaid.create(props);
            this.$emit("plaidLoaded");
            this.onLoad();
        },
        handleOnClick() {
            this.updateLoading(true)
            const institution = this.institution || null;
            if (window.linkHandler) {
                window.linkHandler.open(institution);
            }
        },
        loadScript(src) {
            return new Promise(function (resolve, reject) {
                if (document.querySelector('script[src="' + src + '"]')) {
                resolve();
                return;
                }

                const el = document.createElement("script");

                el.type = "text/javascript";
                el.async = true;
                el.src = src;

                el.addEventListener("load", resolve);
                el.addEventListener("error", reject);
                el.addEventListener("abort", reject);

                document.head.appendChild(el);
            });
        },
    },
    watch: {
        $props: {
        handler(newVal) {
            if (newVal.token) {
            this.onScriptLoaded();
            }
        },
        deep: true,
        },
    },
};
</script>